<template>
  <section id="404">
    <v-app>
    <v-main class="main" fill-height>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="6">
            <div class="display-1 text-center">
              404
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    </v-app>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>